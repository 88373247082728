html {
  --scrollbarBG: #f1f1f1;
  --thumbBG: #c0c0c0;
}
.filter-scroll-class::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
.filter-scroll-class {
  //   scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.filter-scroll-class::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.filter-scroll-class::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  //   border-radius: 6px;
  //   border: 3px solid var(--scrollbarBG);
}
.close_mark {
  display: none;
}
.slide-pane__content::-webkit-scrollbar {
  width: 10px;
  z-index: 2;
}

.slide-pane__content {
  //   scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.slide-pane__content::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.slide-pane__content::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  //   border-radius: 6px;
  //   border: 3px solid var(--scrollbarBG);
}

.slide-pane__content {
  overflow: inherit !important;
}
.product_details_sec {
  overflow-y: auto;
}

.search_nav {
  display: none;
  font-size: 0.8em;
  letter-spacing: 0.7px;
  color: #2d2d2d;
  margin-top: 25px;
}
.logo_out {
  display: none;
}
button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}
.scrollTopIcon {
  padding: 5px;
  left: auto;
  width: 25px;
  z-index: 2;
  height: 25px;
  line-height: 15px;
  right: 30px;
  bottom: 55px;
  background: transparent;
  img {
    width: 14px;
    filter: invert(0%);
    -webkit-filter: invert(0%);
  }
}
.filter_icon {
  position: absolute;
  top: 14px;
  left: 10px;
  z-index: 9999; /* sankar 26-10-20 */
  // z-index: 1;
  display: none;
  width: auto;
  background: transparent;
  height: 28px;
  text-align: center;
  img {
    width: 14px;
    opacity: 0.6;
  }
  span {
    display: inline-block;
    font-size: 11px;
    color: #303030;
    vertical-align: baseline;
    text-transform: uppercase;
  }
  &:hover {
    text-decoration: none;
  }
}
.filtersliding {
  margin-top: 95px;
  background: #f8f8f8;
  .slide-pane__header {
    height: 0px;
    flex: 0 0 0px;
  }
  .filter_sec {
    // margin-left: 30px;
    margin-left: 6px;
    margin-top: 20px;

    .card {
      border: none;
      background: transparent;
      cursor: pointer;
      .card-header {
        background: transparent;
        font-size: 14px;
        color: rgb(48, 48, 48);
        letter-spacing: 0.17px;
        padding: 10px 0px;
        border-bottom: none;
      }
      .filter_ul {
        margin: 0px;
        padding: 0px;
        li {
          list-style: none;
          font-size: 14px;
          margin: 4px 0px;
          color: #fff;
          padding: 3px 7px;
          // width: -webkit-fit-content;
          // width: -moz-fit-content;
          // width: fit-content;
          display: table;
          &.active {
            color: #000;
          }
          &.new_badge {
            background: #c5c5c5 0 0 no-repeat padding-box;
          }
          &.eco_badge {
            background: #e2dbd5 0 0 no-repeat padding-box;
          }
          &.wmb_badge {
            background: #9b6b6b 0 0 no-repeat padding-box;
          }
        }
      }
      .filter_ul_other {
        margin: 0px;
        padding: 0px;
        li {
          list-style: none;
          font-size: 14px;
          margin: 4px 0px;
          color: rgb(147, 147, 147);
          display: table;
          &.active {
            color: #000;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              width: 6px;
              height: 6px;
              background: #000;
              border-radius: 50%;
              right: -24px;
              top: 9px;
            }
          }
        }
      }
      .card-body {
        padding: 15px 0px;
        .rc-slider {
          width: 85%;
          margin-left: 10px;
          .rc-slider-rail {
            background-color: #cecece;
            height: 5px;
          }
          .rc-slider-track {
            height: 5px;
            background-color: #000;
          }
          .rc-slider-handle {
            border: solid 2px #000;
            &:active {
              box-shadow: 0 0 5px #000000;
            }
          }
        }
        .range_p {
          font-size: 14px;
          margin: 10px 0px 0px;
          color: #939393;
        }
      }
    }
  }
}
.product_left {
  .accordion {
    position: sticky;
    // top: 150px;
    top: 309px; // sankar
    background: #f8f8f8;
  }
}
.new_error_msg {
  color: #848282;
  font-size: 12px;
  position: relative;
  top: 0px;
  opacity: 1 !important;
}
.space_div {
  height: 50px;
}
.test {
  ul {
    margin: 0px;
    padding: 10px;
    li {
      list-style: none;
      font-size: 14px;
      color: #303030;
      letter-spacing: 0.17px;
      line-height: 22px;
      padding: 5px 10px;
      text-transform: capitalize;
      &:hover {
        background: #e0e0e0;
      }
    }
  }
}
@media (max-width: 993px) {
  .product_left {
    .accordion {
      position: inherit;
      background: #fff !important;
    }
  }
  .filtersliding .filter_sec {
    margin-top: 45px;
    margin-left: 50px;
  }
  div.search_sticky {
    z-index: 2 !important;
  }
  .navbar-nav {
    margin-top: 10px;
    a {
      margin: 0px 0px 0px 20px !important;
    }
  }
  .signup {
    padding-bottom: 70px !important;
    .my_account_div {
      position: inherit !important;
      padding-bottom: 70px !important;
    }
  }
  .signin {
    &.fav_right {
      padding-bottom: 70px;
    }
    .orders_box {
      width: 94%;
    }
  }
  .products_sec {
    .product_box {
      .figure {
        .figure-caption {
          .list_ul li {
            word-break: break-all;
          }
          p {
            word-break: break-all;
          }
        }
      }
    }
    .zoomimg {
      margin-left: 10px !important;
      width: 75% !important;
      max-width: 380px;
      .actual_img {
        > div > div:first-of-type {
          // margin-left: 0px !important;
        }
      }
    }
    .product_div {
      width: 100% !important;
      padding-bottom: 175px;
    }
    .imgthumb {
      width: 65px !important;
    }
  }
  .overlay {
    .product_details_sec {
      .product_info_right {
        margin-left: 15px !important;
        width: 95%;
      }
    }
  }
  .top_header .navbar .navbar-nav a span {
    display: none;
  }
  .navbar-brand {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .products_sec .product_div .fav_next {
    padding-bottom: 80px;
  }
  .search_nav,
  .logo_out {
    display: block !important;
  }
  .logout_btn {
    display: none !important;
  }
  .filter_icon {
    display: block;
  }
  .products_sec .product_left {
    display: none;
  }

  .slide-pane_from_left.ReactModal__Content--after-open {
    background: #fff;
  }
  .products_sec .search_box input {
    min-height: 60px !important;
    // padding-left: 29% !important;
  }
  .products_sec .search_box .test p {
    top: -10px !important;
    margin-bottom: 0px;
  }
  .products_sec .search_box .filter_selected_list .selested {
    span {
      font-size: 12px !important;
      margin-left: 10px !important;
    }
    padding: 2px 8px !important;
  }
  .products_sec .search_box .test span {
    top: 17px !important;
    left: 95px !important;
  }
  .searchresults > div {
    width: 100% !important;
    top: 60px !important;
  }
  .product_right .row {
    margin-left: 0px !important;
  }
  .signin {
    min-height: auto;
  }
  .zoomimg {
    .actual_img {
      > div > div:first-of-type {
        margin-left: 0px !important;
      }
    }
  }
  .slide-pane__content {
    margin-top: 30px;
    overflow-x: hidden;
    background: #fff;
  }
  .slide-pane_from_left.ReactModal__Content--after-open {
    width: 100% !important;
  }
  #basic-navbar-nav {
    margin-top: 0px;
    background: #f8f8f8;
    position: absolute;
    right: 0px;
    width: 100%;
    text-align: right;
    padding-right: 20px;
    top: 88px;
    height: 100%;
    min-height: 72vh;
  }
  .top_header .navbar {
    margin: 0px -15px;
  }
  .navbar-nav a {
    margin: 25px 0px 25px 20px !important;
  }
  .product_right {
    .row {
      margin-left: 0px !important;
      .col-md-3 {
        width: 50%;
      }
    }
  }
  .products_sec .search_box {
    margin-left: -14px !important;
    margin-right: -8px !important;
    padding-top: 0px;
  }
  .products_sec .zoomimg,
  .products_sec .zoomimg .actual_img,
  .products_sec .actual_img > div {
    height: 250px !important;
  }
  .products_sec .product_div .space-50 {
    height: 35px !important;
  }
  .close_text {
    display: none;
  }
  .close_mark {
    display: block;
  }
  .mobile_tab .close_mark {
    display: none;
  }
  .products_sec .search_box {
    border-bottom: none !important;
  }
  .slide-pane__close {
    right: -10px !important;
    top: 175px !important;
    transform: rotate(0deg) !important;
    background: transparent !important;
    font-size: 20px !important;
    color: #0a0a0a !important;
    z-index: 1 !important;
  }
  .filtersliding .slide-pane__close {
    top: 120px !important;
  }
  // .products_sec .zoomimg {
  //   z-index: 2;
  //   position: relative;
  // }
  .overlay .product_details_sec .product_info_right {
    width: 95%;
    background: #fff;
    z-index: 1;
  }
  .product_info_right.mt-50 {
    margin-top: 0px !important;
    padding-top: 40px;
  }
  .products_sec {
    background: #fff !important;
  }
  .products_sec .product_box .figure .figure-caption .list_ul {
    display: none;
  }
  .products_sec .product_box {
    height: auto !important;
    min-height: 232px !important;
  }
  .products_sec .product_box .figure .figure-caption p {
    margin-bottom: 0px !important;
  }
  .products_sec .product_right .product_item_box {
    // width: 250px; // ganesh
    margin: 10px auto 25px !important;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 768px) {
  /* Safari only override */
  ::i-block-chrome,
  .slide-pane__close {
    top: 200px !important;
  }
  ::i-block-chrome,
  .filtersliding .slide-pane__close {
    top: 135px !important;
  }
}

@media (min-width: 769px) and (max-width: 993px) {
  .signup {
    padding-bottom: 70px !important;
    .my_account_div {
      position: sticky !important;
      padding-bottom: 70px !important;
    }
    .products_sec .product_left {
      width: 30%;
    }
    .products_sec .product_right {
      width: 70%;
    }
    .products_sec .product_right .product_item_box {
      // width: 250px; // ganesh
      margin: 10px auto 25px !important;
    }
    .products_sec .product_box .figure .figure-img {
      max-width: 250px;
      height: 250px;
    }
  }
}
@media (min-width: 521px) and (max-width: 768px) {
  .top_header {
    padding: 10px 10px;
  }
}

@media (min-width: 994px) and (max-width: 1180px) {
  .signup {
    padding-bottom: 70px;
    .my_account_div {
      position: inherit !important;
      padding-bottom: 70px;
    }
  }
  .signin {
    &.fav_right {
      padding-bottom: 70px;
    }
    .orders_box {
      width: 94%;
    }
  }
  .products_sec {
    .product_div {
      padding-bottom: 175px;
    }
  }
  .overlay {
    .product_details_sec {
      .product_info_right {
        margin-left: 60px !important;
      }
    }
  }
}

@media (min-width: 1180px) and (max-width: 1921px) {
  /* sankar 27-10 */
  // .search_box{
  //     margin-left: 50px;
  //     margin-right: 150px;
  // }
  /* sankar 27-10 end */
}

@media (min-width: 1200px) and (max-width: 1420px) {
  .products_sec .product_box .figure .figure-img {
    height: 220px !important;
  }
  .products_sec .filter_sec .card .card-header {
    padding: 7px 0px !important;
  }
  .product_info_middle {
    margin-left: 100px;
  }
}

@media (min-width: 1921px) {
  .products_sec {
    .product_right {
      .product_item_box {
        // width: 440px !important;
      }
    }
    .product_box {
      .figure {
        width: 100%;
        .figure-img {
          max-width: 100% !important;
          height: 440px !important;
        }
      }
    }
    /* sankar 27-10 */
    // .search_box{
    //     margin-left: 70px;
    //     margin-right: 70px;
    // }
    /* sankar 27-10 end */
  }
  .products_sec .wmb_badge {
    margin-top: -1px;
  }
  .products_sec .pro-badge-container {
    top: 0px !important;
    right: 0px !important;
  }
  .product_info_middle {
    margin-left: 105px;
  }
  .overlay .product_details_sec {
    padding-top: 85px;
  }
  .slide-pane_from_left {
    width: 95% !important;
  }
  .slide-pane__content {
    overflow-y: inherit;
  }
}

@media (min-width: 1800px) and (max-width: 1920px) {
  .products_sec .wmb_badge {
    margin-top: -1px;
  }
  .products_sec .pro-badge-container {
    top: 0px !important;
    right: 0px !important;
  }
  .product_info_middle {
    margin-left: 105px;
  }
  .overlay .product_details_sec {
    padding-top: 85px;
  }
  .slide-pane_from_left {
    width: 75% !important;
  }
  .slide-pane__content {
    overflow-y: hidden !important;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .products_sec .pro-badge-container {
    top: 0px !important;
    right: 0px !important;
  }
}
.navbar-toggler {
  padding: 5px;
  border: none;
  position: absolute;
  right: 0px;
  top: 9px;
}
.navbar-toggler-icon {
  width: 1.2em;
  height: 1.2em;
}
.navbar-brand {
  margin: 0 auto;
}
