footer {
  padding: 10px 0px;
  position: fixed;
  bottom: 0px;
  background: #fff;
  z-index: 2;
  width: 100%;
  .footer_links {
    margin: 0px;
    padding: 0px;
    li {
      display: inline-block;
      font-size: 10px;
      letter-spacing: 0.2px;
      color: rgb(45, 45, 45);
      margin: 0 15px;
      a {
        color: rgb(45, 45, 45);
      }
    }
  }
  .social_links {
    margin: 0px;
    padding: 0px;
    li {
      display: inline-block;
      margin: 0 15px;
      line-height: 0px;
    }
  }
}

@media (max-width: 768px) {
  footer {
    position: inherit;
    padding: 6px 0px;
    .footer_links {
      text-align: center;
    }
    .social_links {
      text-align: center !important;
    }
  }
}
