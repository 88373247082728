.products_sec {
  background: #f8f8f8;
  padding: 0px 20px 50px;
  position: relative;
  // height: 100vh;
  .product_right {
    // width: 77%;
    // width: 85%; // ganesh
    .product_item_box {
      // width: 270px; // ganesh
      height: 100%;
      width: 100%;
      display: inline-block;
    }
  }
  .product_left {
    // width: 15%; // ganesh
    // width: 23%; //sankar 27-10
    padding-left: 80px; // sankar 27-10
    // position: sticky; // sankar 28-10
    // max-height: 429px;
    top: 250px;
    background-color: #f8f8f8;
    overflow: auto;
  }
  .product_left_gap {
    // width: 15%; // ganesh
    // width: 23%; //sankar 27-10
    padding-left: 80px; // sankar 27-10
    position: sticky;
    top: 289px;
    background-color: #f8f8f8;
  }
  .product_left {
    // width: 15%; // ganesh
    // width: 23%; //sankar 27-10
    padding-left: 80px; // sankar 27-10
    position: sticky;
    top: 250px;
    background-color: #f8f8f8;
  }
  @keyframes blink {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  .search_box {
    // min-height: 153px;
    border-bottom: 1px solid #303030;
    margin-left: 35px;
    margin-right: 35px;
    .test {
      margin-bottom: 0px;
      position: relative;
      i {
        position: absolute;
        width: 1px;
        height: 28px;
        background-color: #000;
        left: 0px;
        top: 0px;
        animation-name: blink;
        animation-duration: 800ms;
        animation-iteration-count: infinite;
        opacity: 1;
        right: 0px;
        margin: 0 auto;
      }
      span {
        position: absolute;
        top: 38px;
        // top: 66px; // ganesh
        left: 80px; // ganesh
        // left: 56px;
        right: 0px;
        margin: 0 auto;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
        width: 105px;
      }
      p {
        text-align: center;
        font-size: 14px;
        color: #9e9e9e;
        letter-spacing: -0.05px;
        position: relative;
        // top: -50px, // ganesh
        top: -35px;
        // margin-left: -39px // ganesh
        margin-left: -13px;
      }
    }
    input {
      border: none;
      min-height: 100px;
      // min-height: 160px; // ganesh
      background: #f8f8f8;
      font-size: 20px;
      text-align: center;
      font-weight: 500;
      letter-spacing: 2px;
      padding-left: 93px;
      width: 300px;
      margin: 0 auto;
      &:focus + span {
        display: none;
      }
      &::-webkit-input-placeholder {
        color: #000;
        text-transform: uppercase;
        text-align: center;
      }
      &:-ms-input-placeholder {
        color: #000;
        text-transform: uppercase;
        text-align: center;
      }
      &::placeholder {
        color: #000;
        text-transform: uppercase;
        text-align: center;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .filter_selected_list {
      position: relative;
      bottom: 0px;
      .selested {
        background-color: #e4e7eb;
        padding: 10px 10px;
        font-size: 14px;
        text-align: center;
        margin-right: 8px;
        color: #425a70;
        letter-spacing: 0px;
        display: inline-block;
        position: relative;
        top: -6px;
        border-radius: 2px;
        cursor: pointer;
        margin-bottom: 5px;
        span {
          font-size: 14px;
          font-weight: 500;
          margin-left: 24px;
          img {
            width: 10px;
            position: relative;
            top: -2px;
            right: 2px;
          }
        }
      }
    }
  }
  .filter_sec {
    .card {
      border: none;
      background: transparent;
      cursor: pointer;
      .card-header {
        background: transparent;
        font-size: 14px;
        color: rgb(48, 48, 48);
        letter-spacing: 0.17px;
        padding: 10px 0px;
        border-bottom: none;
      }
      .filter_ul {
        margin: 0px;
        padding: 0px;
        li {
          list-style: none;
          font-size: 14px;
          margin: 4px 0px;
          color: #fff;
          padding: 3px 7px;
          // width: -webkit-fit-content;
          // width: -moz-fit-content;
          // width: fit-content;
          display: table;
          &.active {
            color: #000;
          }
          &.new_badge {
            background: #c5c5c5 0 0 no-repeat padding-box;
          }
          &.eco_badge {
            background: #e2dbd5 0 0 no-repeat padding-box;
          }
          &.wmb_badge {
            background: #9b6b6b 0 0 no-repeat padding-box;
          }
        }
      }
      .filter_ul_other {
        margin: 0px;
        padding: 0px;
        li {
          list-style: none;
          font-size: 13px;
          margin: 4px 0px;
          color: rgb(147, 147, 147);
          display: table;
          &.active {
            color: #000;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              width: 6px;
              height: 6px;
              background: #000;
              border-radius: 50%;
              right: -24px;
              top: 9px;
            }
          }
        }
      }
      .card-body {
        padding: 15px 0px;
        .rc-slider {
          width: 85%;
          margin-left: 10px;
          .rc-slider-rail {
            background-color: #cecece;
            height: 5px;
          }
          .rc-slider-track {
            height: 5px;
            background-color: #000;
          }
          .rc-slider-handle {
            border: solid 2px #000;
            &:active {
              box-shadow: 0 0 5px #000000;
            }
          }
        }
        .range_p {
          font-size: 14px;
          margin: 10px 0px 0px;
          color: #939393;
        }
      }
    }
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: #000000;
    box-shadow: 0 0 0 5px #888;
  }
  .mt-50 {
    margin-top: 40px;
  }
  .product_box {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    cursor: pointer;
    position: relative;
    height: 100%;
    .figure {
      a {
        background: #fff;
        display: block;
      }
      margin: 0px;
      transition: all 0.2s ease-in-out;
      .figure-img {
        width: 100%;
        // max-width: 275px; // ganesh
        height: 270px;
        object-fit: cover;
        margin: 0px;
        transition: all 0.2s ease-in-out;
      }
      .figure-caption {
        padding: 10px;
        .pro_id {
          padding-bottom: 8px;
          display: inline-block;
          color: #9e9e9e;
          font-size: 13px;
        }
        p {
          font-size: 14px;
          color: #000;
          letter-spacing: -0.05px;
          margin-bottom: 25px;
          word-break: break-all;
        }
        .favorite {
          float: right;
          img {
            width: 14px;
          }
        }
        .list_ul {
          margin: 0px;
          padding: 0px;
          li {
            display: inline-block;
            color: rgb(48, 48, 48);
            font-size: 12px;
            line-height: 20px;
            letter-spacing: -0.05px;
            margin: 0px 1px;
          }
        }
      }
      .zoomed_img {
        display: none;
        transition: all 0.2s ease-in-out;
      }
      &:hover {
        .zoomed_img {
          display: block;
          -ms-transform: scale(0.85);
          -webkit-transform: scale(0.85);
          transform: scale(0.85);
        }
        .normal_img {
          display: none;
        }
      }
    }
  }
  .pro-badge-container {
    font-size: 12px;
    color: #f4f4f4 !important;
    text-transform: uppercase;
    letter-spacing: 3.4px;
    text-align: center;
    line-height: 21px;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 80px;
    z-index: 1;
  }
  .pro_new_badge {
    background: #c5c5c5 0 0 no-repeat padding-box;
    padding: 4px 15px;
  }
  .eco_badge {
    background: #e2dbd5 0 0 no-repeat padding-box;
    padding: 4px 15px;
  }
  .wmb_badge {
    background: #9b6b6b 0 0 no-repeat padding-box;
    padding: 4px 15px;
  }
  .imgthumb {
    float: left;
    width: 90px;
    .figure {
      margin: 2px 0px;
    }
    img {
      width: 90px;
      height: 90px;
      object-fit: cover;
    }
  }
  .zoomimg {
    width: 420px;
    float: left;
    margin-left: 12px;
    height: 490px;
    cursor: crosshair;
    img {
      // width: 100%;
      // max-width: 420px;
      // height: 490px;
      object-fit: cover;
    }
    .actual_img {
      height: 490px;
      width: 100%;
      > div > div:first-of-type {
        margin-left: 10px;
      }
      // img{
      //     // height: 490px;
      //     width: 100%;
      // }
      // &div:first-child{
      //     height: 490px;
      // }
    }
  }
  .actual_img > div img {
    height: 490px;
  }
  .actual_img > div > div {
  }
  .actual_img > div > div img {
    height: auto;
  }
  .actual_img > div > div:first-child {
    width: 420px !important;
    top: 0px !important;
    height: 490px !important;
  }
  .product_div {
    width: 340px;
    .space-50 {
      height: 65px;
    }
    .product_detail_ul {
      margin: 0px;
      padding: 0px;
      margin-top: 5px;
      li {
        list-style: none;
        font-size: 12px;
        letter-spacing: 0px;
        line-height: 0px;
        color: #000;
        &.right_info {
          float: right;
          u {
            a {
              color: #000;
              text-decoration: none;
            }
          }
        }
      }
    }
    .product_detail {
      margin: 5px 0px 0px;
      padding: 0px;
      border-bottom: 0.8px solid #939393;
      td {
        list-style: none;
        font-size: 12px;
        letter-spacing: 0px;
        // line-height: 30px;
        color: rgb(147, 147, 147);
        vertical-align: top;
        padding: 8px 0px;
        // display: inline-block;
        // vertical-align: super;
        &.right_info {
          float: right;
          position: relative;
          top: 0px;
          margin-left: 35px;
          &.line_height {
            // line-height: 16px;
            text-align: right;
          }
        }
      }
      &.characteristic_ul {
        display: inline-block;
        padding-bottom: 5px;
        .right_info {
          width: 55%;
          line-height: 15px;
          top: 0px;
          text-align: right;
        }
      }
    }
    .fav_next {
      margin-top: 60px;
      p {
        font-size: 12px;
        letter-spacing: 0px;
        line-height: 20px;
        color: #000;
        margin-bottom: 0px;
      }
      img {
        width: 10px;
        margin-bottom: 6px;
      }
      .left {
        float: left;
        border-bottom: 1px solid #000;
        a {
          text-decoration: none;
        }
      }
      .right {
        float: right;
        border-bottom: 1px solid #000;
        a {
          text-decoration: none;
        }
      }
    }
  }
}

.modal-backdrop {
  z-index: 0;
}

.overlay {
  &:before {
    // background-color: rgba(67, 90, 111, 0.7);
    left: 0px;
    top: 0px;
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    content: " ";
  }
  .product_details_sec {
    margin-top: 45px;
    position: relative;
    height: 100vh;
    // width: 95%;
    // left: -95%;
    // -webkit-animation: slide 1.5s forwards;
    // animation: slide 1.5s forwards;
    // top:-10px;
    // animation-name: reducetime;
    // animation-duration: 2s;
    // -webkit-animation-name: reducetime;
    // -webkit-animation-duration: 2s;
    // -moz-animation-name: reducetime;
    // -moz-animation-duration: 2s;
    // -o-animation-name: reducetime;
    // -o-animation-duration: 2s;
    .close_btn {
      position: absolute;
      transform: rotate(-90deg);
      top: 45vh;
      right: -52px;
      box-shadow: none;
      background: #303030;
      border: #303030;
      box-shadow: none;
      font-size: 10px;
      padding: 8px 16px;
      line-height: 15px;
      letter-spacing: 2.4px;
      color: #f4f4f4;
      text-transform: uppercase;
      text-align: center;
      a {
        color: #fff;
      }
    }
    .product_info_right {
      margin-left: 100px;
    }
  }
}

@-webkit-keyframes slide {
  100% {
    left: 0px;
    top: 0px;
  }
}

@keyframes slide {
  100% {
    left: 0px;
    top: -10px;
  }
}

@media (max-width: 768px) {
  .products_sec {
    .product_left {
      width: 100%;
    }
    .product_right {
      width: 100%;
      .product_item_box {
        margin: 10px auto;
        // width: 135px; // ganesh
        .product_box {
          .figure {
            .figure-img {
              height: 126px;
            }
          }
        }
      }
    }
    .search_box {
      .filter_selected_list {
        position: relative;
        max-height: 30px;
        overflow: scroll;
      }
    }
    div.search_sticky {
      top: 98px !important;
    }
  }
}

@keyframes reducetime {
  0% {
    width: 0%;
    left: -100%;
  }
  100% {
    width: 95%;
    left: 0%;
  }
  0% {
    width: 0%;
    left: -100%;
  }
}

@-webkit-keyframes reducetime {
  0% {
    width: 0%;
    left: -100%;
  }
  100% {
    width: 95%;
    left: 0%;
  }
  0% {
    width: 0%;
    left: -100%;
  }
}

@-moz-keyframes reducetime {
  0% {
    width: 0%;
    left: -100%;
  }
  100% {
    width: 95%;
    left: 0%;
  }
  0% {
    width: 0%;
    left: -100%;
  }
}

@-o-keyframes reducetime {
  0% {
    width: 0%;
    left: -100%;
  }
  100% {
    width: 95%;
    left: 0%;
  }
  0% {
    width: 0%;
    left: -100%;
  }
}

@keyframes hidetime {
  100% {
    width: 95%;
    left: 0%;
  }
  0% {
    width: 0%;
    left: -100%;
  }
}

@-webkit-keyframes hidetime {
  100% {
    width: 95%;
    left: 0%;
  }
  0% {
    width: 0%;
    left: -100%;
  }
}

@-moz-keyframes hidetime {
  100% {
    width: 95%;
    left: 0%;
  }
  0% {
    width: 0%;
    left: -100%;
  }
}

@-o-keyframes hidetime {
  100% {
    width: 95%;
    left: 0%;
  }
  0% {
    width: 0%;
    left: -100%;
  }
}

// .Product_popup{
//     position: fixed;
//     margin: auto;
//     width: 95%;
//     height: 100%;
//     -webkit-transform: translate3d(0%, 0, 0);
//         -ms-transform: translate3d(0%, 0, 0);
//             -o-transform: translate3d(0%, 0, 0);
//             transform: translate3d(0%, 0, 0);
//     .modal-dialog{
//         width: 100%;
//         max-width: 100%;
//         margin: 0px 0px 0px -20px;
//         min-height: 100%;
//         .modal-content{
//             width: 95%;
//             margin: 0px;
//             height: 100vh;
//             animation-name: reducetime;
//             animation-duration: 2s;
//             -webkit-animation-name: reducetime;
//             -webkit-animation-duration: 2s;
//             -moz-animation-name: reducetime;
//             -moz-animation-duration: 2s;
//             -o-animation-name: reducetime;
//             -o-animation-duration: 2s;
//             padding: 0px;
//             .modal-body{
//                 padding: 0px;
//             }
//             .close{
//                 position: absolute;
//                 transform: rotate(-90deg);
//                 top: 45vh;
//                 right: -41px;
//                 box-shadow: none;
//                 background: #303030;
//                 border: #303030;
//                 box-shadow: none;
//                 font-size: 10px;
//                 padding: 8px 16px;
//                 line-height: 15px;
//                 letter-spacing: 2.4px;
//                 color: #f4f4f4;
//                 text-transform: uppercase;
//                 text-align: center;
//                 opacity: 1;
//                 text-shadow:none;
//                 span{
//                     opacity: 0;
//                     &.sr-only{
//                         opacity: 1;
//                         position: relative;
//                     }
//                 }
//                 a{
//                     color: #fff;
//                 }
//             }
//         }
//     }
// }
.product_model {
  padding-left: 0px !important;
  z-index: 0;
}

.product_model.modal .modal-dialog {
  width: 100%;
  max-width: 95%;
  height: 100vh;
  margin: 0;
  transform: translateX(0%);
  -moz-transition: transform 1.2s;
  -webkit-transition: transform 1.2s;
  -o-transition: transform 1.2s;
  transition: transform 1.2s;
}

.product_model.modal .modal-dialog .close {
  position: absolute;
  transform: rotate(-90deg);
  top: 45vh;
  right: -41px;
  box-shadow: none;
  background: #303030;
  border: #303030;
  box-shadow: none;
  font-size: 10px;
  padding: 8px 16px;
  line-height: 15px;
  letter-spacing: 2.4px;
  color: #f4f4f4;
  text-transform: uppercase;
  text-align: center;
  opacity: 1;
  text-shadow: none;
}

.product_model.modal .modal-dialog .close span {
  opacity: 0;
}

.product_model.modal .modal-dialog .close span.sr-only {
  opacity: 1;
  position: relative;
}

.product_model.modal .modal-dialog .modal-content {
  height: inherit;
  border: 0;
  border-radius: 0;
  width: 100%;
  padding: 0px;
  -moz-transition: transform 1.2s;
  -webkit-transition: transform 1.2s;
  -o-transition: transform 1.2s;
  transition: transform 1.2s;
}

.product_model.modal .modal-dialog .modal-content .modal-body {
  overflow-y: hide;
  padding: 0px;
}

.modal.fixed-left .modal-dialog {
  margin-left: auto;
  transform: translateX(100%);
  -moz-transition: transform 1.2s;
  -webkit-transition: transform 1.2s;
  -o-transition: transform 1.2s;
  transition: transform 1.2s;
}

.modal.fixed-right .modal-dialog {
  margin-right: auto;
  transform: translateX(-100%);
  -moz-transition: transform 1.2s;
  -webkit-transition: transform 1.2s;
  -o-transition: transform 1.2s;
  transition: transform 1.2s;
}

.product_model.modal.show .modal-dialog {
  transform: translateX(0%);
  -moz-transition: transform 1.2s;
  -webkit-transition: transform 1.2s;
  -o-transition: transform 1.2s;
  transition: transform 1.2s;
}

.js-image-zoom__zoomed-image {
  z-index: 1;
}
.slide-pane__overlay {
  z-index: 1;
}
// .slide-pane__header{
//     display: none;
// }
.slide-pane__close {
  position: absolute;
  right: -74px;
  top: 48%;
  z-index: 99;
  transform: rotate(-90deg);
  background: #303030;
  border: #303030;
  box-shadow: none;
  font-size: 10px;
  padding: 8px 0px !important;
  line-height: 15px;
  letter-spacing: 2.4px;
  color: #f4f4f4;
  text-transform: uppercase;
  text-align: center;
  opacity: 1 !important;
  text-shadow: none;
  height: 32px;
  width: 115px;
}

.slide-pane__content {
  padding: 0px !important;
  // overflow-y: hidden !important;
}

@media (min-width: 1921px) and (max-width: 3000px) {
  .products_sec .imgthumb {
    width: 200px;
  }
  .products_sec .imgthumb img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
  .products_sec .zoomimg {
    width: 620px;
    height: 690px;
    cursor: crosshair;
  }
  .products_sec .product_div .product_detail.characteristic_ul {
    display: block;
    padding-bottom: 5px;
  }
  .products_sec .product_div {
    width: 520px;
  }
  .products_sec .zoomimg img {
    object-fit: cover;
    width: 620px !important;
    height: 690px !important;
  }
  .js-image-zoom__zoomed-image {
    width: 620px !important;
    height: 690px !important;
  }
}

.fade {
  transition: opacity 1.15s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  -webkit-transition: opacity 1.15s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  -moz-transition: opacity 1.15s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  -o-transition: opacity 1.15s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

/* sankar 26-10 */
.filter-scroll-class {
  overflow-x: hidden;
  min-height: auto;
  max-height: 250px;
}

.filter-scroll-class::-webkit-scrollbar {
  width: 7px;
}
div.search_sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 110px;
  background-color: #f8f8f8;
  font-size: 20px;
  z-index: 2;
  // padding-bottom: 50px;
  margin-left: -30px;
  margin-right: -35px;
}

.products_sec .mt-50 {
  // margin-top: 0px !important;
}

.product_right .row {
  margin-left: 40px;
}
/* sankar 26-10 end */

.auto-suggest-item:hover {
  background: #cccccc;
}
.auto-suggest-item-selected {
  background: #cccccc;
}
.searchresults > div {
  border: 1px solid #ecebeb;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 75px;
  background: #fff;
  left: 0px;
  right: 0px;
  max-width: 325px;
}

row {
  width: 100%;
}
.productlist-main {
  width: 100%;
  display: block;
}
// .products_sec .product_left {
//   overflow-x: scroll;
// }
.view-more-btn .btn.btn-default {
  // background-color: #dee2e6;
  font-size: 12px;
  color: #f4f4f4 !important;
  text-transform: uppercase;
  letter-spacing: 3.4px;
  text-align: center;
  line-height: 21px;
  background: #c5c5c5 0 0 no-repeat padding-box;
  padding: 4px 15px;
  border-radius: 0px;
  margin-left: -15px;
}
@media (max-width: 480px) {
  .actual_img {
    width: 100%;
    overflow: hidden;
  }
  .product_info_middle {
    width: 100%;
    overflow: hidden;
  }
}
.inner-tbl-content {
  min-height: 900px;
}
