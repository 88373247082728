.signin {
  background: #f8f8f8;
  padding-top: 125px;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 6px;
  position: relative;
  z-index: 1;
  padding-bottom: 60px;
  min-height: 100vh;
  &.home_sign {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 6px;
    position: relative;
    z-index: 1;
  }
  .signin_form {
    width: 235px;
    margin: 0 auto;
    .form-group {
      .form-control {
        font-family: "Open Sans", sans-serif;
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
        border-width: 0px 0px 0.8px;
        border-color: rgb(0, 0, 0);
        border-style: solid;
        font-variant: normal;
        background: transparent none repeat scroll 0% 0%;
        letter-spacing: 0px;
        color: rgb(0, 0, 0);
        font-size: 12px;
        line-height: 30px;
        opacity: 1;
        outline: currentcolor none medium;
        margin-bottom: 19px;
        border-radius: 0px;
        min-height: 45px;
        &::-webkit-input-placeholder {
          color: #000;
          text-transform: uppercase;
          font-weight: 400;
        }
        &:-ms-input-placeholder {
          color: #000;
          text-transform: uppercase;
          font-weight: 400;
        }
        &::placeholder {
          color: #000;
          text-transform: uppercase;
          font-weight: 400;
        }
        &:focus {
          outline: currentcolor none medium;
          box-shadow: none;
        }
      }
    }
    .login_btn {
      width: 237px;
      height: 43px;
      border: 1px solid rgb(0, 0, 0);
      background: transparent none repeat scroll 0% 0%;
      cursor: pointer;
      outline: currentcolor none medium;
      text-transform: uppercase;
      border-radius: 0px;
      color: rgb(0, 0, 0);
      letter-spacing: 3.4px;
      opacity: 0.89;
      font-size: 0.8em;
      line-height: 21px;
      margin-bottom: 19px;
      display: block;
      text-align: center;
      text-decoration: none;
      &:hover {
        background: #000;
        color: #fff;
        opacity: 1;
        outline: none;
      }
      &.login_link {
        line-height: 43px;
        &:focus {
          outline: none;
        }
      }
    }
    p {
      font-size: 12px;
      letter-spacing: 0px;
      line-height: 30px;
    }
    .signin_p {
      text-transform: uppercase;
    }
    .or {
      text-align: center;
      font-size: 12px;
      letter-spacing: 0px;
      line-height: 30px;
      margin-bottom: 19px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 93px;
        border-bottom: 0.8px solid rgb(0, 0, 0);
        left: 0px;
        top: 15px;
      }
      &:after {
        content: "";
        position: absolute;
        width: 93px;
        border-bottom: 0.8px solid rgb(0, 0, 0);
        right: 0px;
        top: 15px;
      }
    }
    .forgot_a {
      font-size: 12px;
      letter-spacing: 0px;
      line-height: 30px;
      color: #000;
      text-align: center;
      display: inherit;
    }
  }
  .favlist {
    width: 100%;
    max-width: 550px;
    .listitem {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
      background: #fff;
      margin-left: 80px;
      position: relative;
      margin-bottom: 23px;
      width: 100%;
      .tb-row {
        display: table-row;
        .tb-cell {
          display: table-cell;
          vertical-align: top;
          img {
            width: 120px;
            height: 120px;
            object-fit: cover;
          }
          &.pd-left {
            padding-left: 15px;
          }
          .product_info {
            padding: 5px;
            .title {
              font-size: 12px;
              margin-top: 10px;
              margin-bottom: 5px;
              color: #000;
              a {
                color: #000;
                text-decoration: none;
              }
            }
            .product_no {
              color: grey;
              font-size: 10px;
              margin-bottom: 6px;
            }
            .product_text {
              font-size: 10px;
              color: #000;
            }
            p {
              margin: 0px;
              letter-spacing: -0.05px;
            }
            ul {
              margin: 0px;
              padding: 0px;
              li {
                list-style: none;
                display: inline-block;
                font-size: 10px;
                color: #000;
                margin: 0 1px;
              }
            }
          }
        }
      }
      .close {
        position: absolute;
        top: 4px;
        right: 12px;
        opacity: 1;
        img {
          width: 10px;
        }
      }
    }
  }
  &.fav_right {
    padding-top: 25px;
    height: 100%;
    padding-bottom: 50px;
    .t-c {
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
    }
  }
  &.fav_left {
    padding-top: 125px;
    text-align: center;
    height: 100%;
  }
}
.modal-content {
  width: 50%;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 20px;
  background: rgb(226, 219, 213) none repeat scroll 0% 0%;
  padding: 50px;
  border-radius: 0px;
  .modal-header {
    border-bottom: 0px;
    padding: 0px;
    .close {
      right: 15px;
      position: absolute;
      top: 10px;
      z-index: 1;
      &:focus {
        outline: 0px auto -webkit-focus-ring-color;
      }
    }
  }
  .modal-body {
    .signin_form {
      width: 235px;
      margin: 0 auto;
      .form-group {
        .form-control {
          font-family: "Open Sans", sans-serif;
          width: 100%;
          box-sizing: border-box;
          padding: 10px;
          border-width: 0px 0px 0.8px;
          border-color: rgb(0, 0, 0);
          border-style: solid;
          font-variant: normal;
          background: transparent none repeat scroll 0% 0%;
          letter-spacing: 0px;
          color: rgb(0, 0, 0);
          font-size: 12px;
          line-height: 30px;
          opacity: 1;
          outline: currentcolor none medium;
          margin-bottom: 19px;
          border-radius: 0px;
          min-height: 45px;
          &::-webkit-input-placeholder {
            color: #000;
            text-transform: uppercase;
          }
          &:-ms-input-placeholder {
            color: #000;
            text-transform: uppercase;
          }
          &::placeholder {
            color: #000;
            text-transform: uppercase;
          }
          &:focus {
            outline: currentcolor none medium;
            box-shadow: none;
          }
        }
      }
      .login_btn {
        width: 237px;
        height: 43px;
        border: 1px solid rgb(0, 0, 0);
        background: transparent none repeat scroll 0% 0%;
        cursor: pointer;
        outline: currentcolor none medium;
        text-transform: uppercase;
        border-radius: 0px;
        color: rgb(0, 0, 0);
        letter-spacing: 3.4px;
        opacity: 0.89;
        font-size: 0.8em;
        line-height: 21px;
        margin-bottom: 39px;
        display: block;
        text-align: center;
        text-decoration: none;
        &:hover {
          background: #000;
          color: #fff;
          opacity: 1;
          outline: none;
        }
        &.login_link {
          line-height: 43px;
          &:focus {
            outline: none;
          }
        }
      }
      p {
        font-size: 12px;
        line-height: 21x;
        letter-spacing: -0.05px;
        margin-bottom: 30px;
      }
      .signin_p {
        text-transform: uppercase;
        margin-top: 39px;
      }
      .or {
        text-align: center;
        font-size: 12px;
        letter-spacing: 0px;
        line-height: 30px;
        margin-bottom: 19px;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          width: 93px;
          border-bottom: 0.8px solid rgb(0, 0, 0);
          left: 0px;
          top: 15px;
        }
        &:after {
          content: "";
          position: absolute;
          width: 93px;
          border-bottom: 0.8px solid rgb(0, 0, 0);
          right: 0px;
          top: 15px;
        }
      }
      .forgot_a {
        font-size: 12px;
        letter-spacing: 0px;
        line-height: 30px;
        color: #000;
        text-align: center;
        display: inherit;
      }
    }
  }
}

.signup {
  background: #e2dbd5;
  box-shadow: none;
  position: initial;
  .signin_form {
    .form-group {
      .form-control {
        opacity: 0.5;
      }
    }
    p {
      opacity: 0.5;
    }
    .login_btn {
      opacity: 0.5;
    }
  }
  .my_account_div {
    display: inline-block;
    margin: 0px 30px;
    text-align: left;
    vertical-align: top;
    position: sticky;
    top: 175px;
    .space {
      height: 65px;
    }
  }
  .fav_class {
    text-align: center;
    opacity: 0.5;
  }
}
.orders_box {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  p {
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 30px;
  }
  .signin_p {
    text-transform: uppercase;
  }
  table {
    font-size: 12px;
    thead {
      background: #f3f3f3 none repeat scroll 0% 0%;
      border-bottom: 1px solid rgb(228, 231, 235);
      tr {
        height: 32px;
        font-weight: 100;
        text-align: center;
        th {
          vertical-align: middle;
          border-bottom: 1px solid #dee2e6;
          font-weight: 500;
          &:last-child {
            text-align: right;
            padding-right: 20px;
          }
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid rgb(237, 240, 242);
        height: 48px;
        td {
          vertical-align: middle;
          text-align: center;
          letter-spacing: 0.7px;
          ul {
            margin: 0px;
            padding: 0px;
            text-align: right;
            li {
              display: inline-block;
              margin: 0px 20px 0px 0px;
              img {
                width: 18px;
              }
            }
          }
        }
      }
    }
  }
  .pagination {
    margin: 0px;
    padding: 0px;
    float: right;
    li {
      margin: 0px 3px;
      a {
        color: #000;
        font-size: 12px;
        padding: 4px 10px;
        letter-spacing: 0.7px;
        &.active {
          background: #9b6b6b;
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .signin {
    padding-top: 25px;
    padding-bottom: 25px;
    height: 100%;
    min-height: auto;
    .favlist {
      .listitem {
        margin-left: 10px;
        width: 95%;
      }
    }
    &.fav_left {
      padding-top: 25px;
    }
  }
  .modal-content {
    width: 100%;
    max-width: 400px;
  }
}
@media (min-width: 1200px) and (max-width: 1420px) {
  .fav_right {
    margin-bottom: 50px;
  }
}
