body {
  background-color: #f6f6f6;
}
// html, body {
//     width: 210mm;
//     height: 335mm;
//     margin: 0 auto;
// }
@media print {
  .top_header,
  .search_sticky,
  .slide-pane__header {
    display: none;
  }

  .products_sec .zoomimg {
    height: auto;
  }
  .products_sec .actual_img > div,
  .products_sec .zoomimg,
  .products_sec .zoomimg .actual_img {
    height: auto !important;
  }
  .products_sec .actual_img > div img {
    height: auto;
  }
  .inner-tbl-content {
    background-color: #fff !important;
  }
  .page-wrapper {
    background-color: #fff;
  }
  tfoot {
    margin-bottom: 5px;
  }
  .product_info {
    background-color: #fff;
  }
  .favlist {
    min-height: 280mm;
    padding-bottom: 2mm;
    margin-bottom: 0;
  }
  .inner-tbl-content {
    height: 298mm;
    background-color: #f6f6f6 !important;
  }
  .fav_right {
    margin-bottom: 0;
  }
  .Section1 {
    margin-top: 60px;
  }
  @page {
    size: a4;
    margin: 0mm;
  }
  .products_sec .product_div {
    width: 500px;
    margin-left: 50px;
    padding-left: 50px;
  }
  tfoot {
    display: table-footer-group;
    break-after: page;
  }

  body,
  page {
    margin: 0;
    box-shadow: 0;
  }

  body {
    background-color: #fff;
  }
  .page-container {
    width: 920px !important;
  }
}
