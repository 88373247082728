@media print {
  .top_header,
  .search_sticky,
  .navbar-toggler,
  .slide-pane__header {
    display: none;
  }
  @page {
    size: A4;
    margin: 0;
  }
  .overlay .product_details_sec {
    height: auto;
  }
  .products_sec .actual_img > div img {
    height: auto;
    max-height: 350px;
  }
  .productlist-main {
    display: none;
  }
  .product_details_sec .product_info_right {
    width: 70% !important;
    margin-left: 0px !important;
    padding-left: 0 !important;
  }

  .product_info_right .product_div {
    width: 100% !important;
    margin: 0 !important;
    padding-left: 0 !important;
  }
  .products_sec .product_div {
    width: 100%;
  }

  // @supports (-webkit-touch-callout: none) {
  .product_details_sec {
    width: 1000px;
  }
  // }
  .print_label {
    display: none;
  }
  .top_header {
    display: block !important;
  }
  .navbar {
    display: flex !important;
  }
  .filter_icon {
    display: none !important;
  }
  #basic-navbar-nav {
    display: none !important;
  }
  footer,
  footer .container-fluid {
    display: none !important;
  }
}
