@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
body{
    margin: 0px;
    padding: 0px;
    font-family: "Open Sans", sans-serif !important;
    // font-family: 'Lato', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;   /* Firefox */
    font-weight: normal;
    font-style: normal;
}
h1{
    margin: 0px;
}
a{
    outline: currentcolor none medium;
    -moz-appearance: none;
    -webkit-appearance: none;
}
.p-0{
    padding: 0px;
}
.plr-5{
    padding: 0px !important;
    margin: 6px 5px 10px;
}
.btn{
    border: none;
    &:focus{
        box-shadow: none !important;
        outline: none !important;
    }   
}
.extra_space{
    height: 50px;
}
#myDiv {
    color:Green;
    background-color:#eee;
    border:2px solid #333;
    display:none;
    text-align:justify;
}
[type=button], [type=reset], [type=submit], button{
    -moz-appearance: none;
    -webkit-appearance: none;
}